<template>
  <BaseCard
    :caption-visible="false"
    :footer-visible="false"
    :actions-visible="isSuperAdmin"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">productos</span>
    </template>
    <template #actions>
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_PRODUCTS,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"

        :to="{ name: 'createProduct' }"
      >
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
        >
          + Añadir producto
        </b-button>
      </b-link>
    </template>
    <BaseTable
      ref="produts-table"
      :resourceStore="'products'"
      :columns="columns"
      :filters="filters"
      :resource="$data.$constants.RESOURCES.RESOURCE_PRODUCTS"
      hide-totals
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearProductListFilters"
      @row-clicked="$router.push({ name: 'viewProduct', params: { id: $event.id } })"
    >
      <template #cell(name)="data">
        <span class="text-gray-50 font-weight-light">
          {{ data.item.name }}
        </span>
      </template>
      <template #cell(category)="data">
        <span> {{ data.item.category ? data.item.category.name : '' }} </span>
      </template>
      <template #cell(purchase_price)="data">
        <span> {{ data.item.purchase_price | numberToLocalString }} €</span>
      </template>
      <template #cell(sale_price)="data">
        <span> {{ data.item.sale_price | numberToLocalString }} €</span>
      </template>

      <template #cell(provider)="data">
        <span> {{ data.item.provider ? data.item.provider.name : '' }}</span>
      </template>
      <template #cell(actions)="data">
        <span>
          <b-link
            :to="{ name: 'viewProduct', params: { id: data.item.id} }"
            class="pr-1 d-inline-block text-indigo"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Ver"
              icon="EyeIcon"
              size="18"
            />
          </b-link>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_PRODUCTS,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
            class="d-inline-block text-danger"
            v-if="isSuperAdmin"
            @click="deleteProduct(data.item.id, data.item.name)"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </b-link>
        </span>
      </template>
    </BaseTable>
    <ProductListFilters
      ref="product-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('products/setFilters', $event)"
    />
  </BaseCard>
</template>

<script>
import ProductsApi from '@/api/products-api'
import ProductListFilters from '@/components/products/filters/ProductListFilters.vue'
import BaseTable from '@/components/ui/table/BaseTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BaseCard,
    BaseTable,
    ProductListFilters,
  },
  data() {
    return {
      columns: [
        {
          label: 'NOMBRE',
          key: 'name',
          sortable: true,
          thStyle: { 'min-width': '250px' },
        },
        {
          label: 'CATEGORÍA',
          key: 'category',
          sortable: true,
          thStyle: { 'min-width': '180px' },
        },
        {
          label: 'SKU',
          key: 'sku',
          sortable: true,
          thStyle: { 'min-width': '100px' },
        },
        {
          label: 'PROVEEDOR',
          key: 'provider',
          sortable: true,
        },
        {
          label: 'PRECIO COSTE',
          key: 'purchase_price',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
          thStyle: { 'min-width': '135px' },
        },
        {
          label: 'PRECIO VENTA',
          key: 'sale_price',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
          thStyle: { 'min-width': '135px' },
        },
        {
          label: 'Acciones',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '200px' },
        },
      ],
      filtersVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'products/getFilters',
    }),
    isSuperAdmin() {
      return JSON.parse(localStorage.getItem('userData')).roles[0].name === 'super_admin'
    },
  },
  methods: {
    handleClearProductListFilters() {
      this.$store.commit('products/setFilters', {})
      this.$refs['product-list-filters'].clearFilters()
    },
    async deleteProduct(productId, name) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      await ProductsApi.delete(productId)
      await this.$refs['produts-table'].loadData()
    },
  },
}
</script>

<style scoped>
</style>
