<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group
          label="Nombre"
          label-for="product-name"
        >
          <b-form-input
            id="product-name"
            :value="model.name"
            name="name"
            placeholder="Nombre"
            @input="update('name', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12">
        <b-form-group
          label="Grupo de categoría"
          label-for="product-group-category"
        >
          <CategoryGroupSelect
            id="product-group-category"
            :value="model.category_group"
            @input="update('category_group', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Categoría"
          label-for="product-category"
        >
          <CategorySelect
            id="product-category"
            :value="model.product_category"
            @input="update('product_category', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="SKU"
          label-for="product-sku"
        >
          <b-form-input
            id="product-sku"
            :value="model.sku"
            name="sku"
            placeholder="SKU"
            @input="update('sku', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="isSuperAdmin" sm="12">
        <b-form-group
          label="Estado"
          label-for="product-status"
        >
          <StatusSelect
            id="product-status"
            :value="model.status"
            :type="statusType"
            @input="update('status', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import CategoryGroupSelect from '@/components/category/select/CategoryGroupSelect.vue'
import CategorySelect from '@/components/category/select/CategorySelect.vue'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import { PRODUCT_STATUS_TYPE } from '@/api/status-api'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductListFiltersForm',
  components: { StatusSelect, CategorySelect, CategoryGroupSelect },
  mixins: [FormMixin],
  data() {
    return {
      statusType: PRODUCT_STATUS_TYPE,
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
    }),
    isSuperAdmin () {
      return this.user.roles.some(role => role.name === 'super_admin')
    }
  }
}
</script>

<style scoped>

</style>
